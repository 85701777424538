import { useRouter } from "next/router";
import * as en from "../../public/Languages/en/index";
import * as es from "../../public/Languages/es/index";

const TRANSLATIONS = { en, es };

export default function useTranslation(path: string) {
    const router = useRouter();
    const { locale, asPath } = router;

    const setLocale = (locale: string) =>
        router.push(asPath, asPath, { locale });

    const t = (keyString: string, count?: number) => {
        const translation = TRANSLATIONS?.[locale]?.[path];
        if (!translation) return null;

        if (count !== undefined) {
            const singularKey = `${keyString}_ONE`;
            const pluralKey = `${keyString}_OTHER`;
            const selectedKey = count === 1 ? singularKey : pluralKey;

            return translation[selectedKey] || null;
        } else {
            return translation[keyString] || null;
        }
    };

    return { t, locale, setLocale };
}
